#chefo-pagho {
  width: 100%;
  height: 100%;
  background-color: var(--fona-koloro);

  display: flex;
  justify-content: center;
  align-items: center;
}

#chefo-pagho .maldekstra-entenilo {
  width: 12%;
  height: 100%;

  padding: 2rem 0 2rem 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

#chefo-pagho .dekstra-entenilo {
  width: 88%;
  height: 100%;

  padding: 3rem;
}

#chefo-pagho .serchobreto-kaj-retaghotitolo {
  width: 100%;
  height: 6rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

#chefo-pagho .ligilo-al-nula-pagho {
  text-decoration: none;
}

#chefo-pagho .klaso-titolo {
  margin-top: 2rem;

  color: var(--teksto-koloro);
}

#chefo-pagho .chambro-entenilo {
  width: 100%;
  height: calc(100% - 6rem);

  display: flex;
  flex-direction: row;
}

#chefo-pagho .instruisto {
  display: flex;
  align-items: flex-end;

  height: 100%;
  width: 20%;
  padding: 2rem;
}

#chefo-pagho .zamenhof {
  height: 30rem;
}

#chefo-pagho .lecionoj {
  height: 100%;
  width: 80%;

  margin-top: 2rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  overflow-y: auto;
}

#chefo-pagho .leciono {
  background-color: var(--griza-koloro);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  height: 20rem;
  width: 30rem;

  margin: 1rem;

  border-radius: 2.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  color: inherit;

  transition: .3s ease all;
}

#chefo-pagho .leciono:hover,
#chefo-pagho .leciono:focus,
#chefo-pagho .leciono:active {
  background-color: var(--orangha-koloro);
  color: var(--blanka-koloro);
}

#chefo-pagho .leciono p {
  font-size: 1.5rem;
  text-align: center;
}

#chefo-pagho .leciono h3 {
  text-align: center;
}

@media (max-width: 750px) {
	#chefo-pagho .maldekstra-entenilo {
    position: fixed;
    left: 0;
    bottom: 0;
    
    padding: 0;

    width: 100%;
    height: 6rem;
  }

  #chefo-pagho .dekstra-entenilo {
    width: 100%;
    height: calc(100% - 8rem);
  }
}