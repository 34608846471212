.butono button {
  background-color: var(--blanka-koloro);
  color: var(--verda-koloro);

  border: none;
  padding: 1rem 5rem;
  font-weight: 300;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

  border-radius: 2.5rem;

  cursor: pointer;
  transition: .3s ease all;
}

.butono button:hover {
  background-color: var(--orangha-koloro);
  color: var(--blanka-koloro);
  box-shadow: 0px 15px 20px var(--blanka-koloro-travidebla);
}