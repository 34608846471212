.serchbreto {
  width: 100%;

  position: relative;
}

.serchbreto .enigujo {
  width: 30%;
  height: 3rem;

  background-color: var(--griza-koloro);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  border-radius: 2.5rem;
  border: none;

  display: flex;
  justify-content: flex-start;

  font-style: italic;
  font-size: 1.2rem;

  padding: 0 4rem 0 2rem;
}

.serchbreto .bildobutono {
  position: absolute;
  top: 0;
  left: calc(30% - 3rem);

  height: 100%;
}

.serchbreto .bildobutono img {
  height: 50%;
  width: auto;
}