#leciono-pagho {
  width: 100%;
  height: 100%;
  background-color: var(--fona-koloro);

  display: flex;
  justify-content: center;
  align-items: center;
}

#leciono-pagho .maldekstra-entenilo {
  width: 12%;
  height: 100%;

  padding: 2rem 0 2rem 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

#leciono-pagho .dekstra-entenilo {
  width: 88%;
  height: 100%;

  padding: 3rem;
}

#leciono-pagho .serchobreto-kaj-retaghotitolo {
  width: 100%;
  height: 6rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

#leciono-pagho .ligilo-al-nula-pagho {
  text-decoration: none;
}

#leciono-pagho .klaso-titolo {
  margin-top: 2rem;

  color: var(--teksto-koloro);
}

#leciono-pagho .chambro-entenilo {
  width: 100%;
  height: calc(100% - 13rem);
  
  background-color: var(--luma-griza-koloro);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);

  border-radius: 28px;
}

#leciono-pagho .klaso-entenilo {
  width: 100%;
  height: calc(100% - 6rem);

  display: flex;
}

#leciono-pagho .dialogoj {
  width: 70%;
  height: 100%;

  background-color: yellow;

  display: flex;
}

#leciono-pagho .ekzercoj {
  width: 30%;
  height: 100%;

  background-color: red;
}


#leciono-pagho .malsupra-entenilo {
  width: 100%;
  height: 6rem;

  display: flex;
  justify-content: center;
  
  position: relative;
}

#leciono-pagho .navigilo {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  width: 3rem;
  height: 3rem;

  margin: 0 0.5rem;
}

#leciono-pagho .navigilo-bildo {
  width: 100%;
  height: 100%;

  color: #707070;
}

#leciono-pagho .butono-ligilo-al-nula-pagho {
  position: absolute;
  right: 0;

  margin: 0 2rem 2rem;
}

@media (max-width: 750px) {
	#leciono-pagho .maldekstra-entenilo {
    position: fixed;
    left: 0;
    bottom: 0;
    
    padding: 0;

    width: 100%;
    height: 6rem;
  }

  #leciono-pagho .dekstra-entenilo {
    width: 100%;
    height: calc(100% - 8rem);
  }
}