.menuo {
  width: 10rem;
  height: 100%;

  background-color: var(--verda-koloro);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  border-radius: 2.5rem;
}

.menuo .butono-al-nula-pagho-entenilo {
  width: 100%;
  height: 8rem;
  margin-top: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.menuo .butono-al-nula-pagho-entenilo img {
  height: 5rem;
  width: auto;
}

.menuo .ceteraj-butonoj-entenilo {
  width: 100%;
  height: calc(100% - 14rem);

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.menuo .ceteraj-butonoj-entenilo img {
  height: 70%;
  width: auto;
}

@media (max-width: 750px) {
	.menuo {
    width: 100%;
    height: 100%;

    border-radius: 0;
	}

  .menuo .butono-al-nula-pagho-entenilo {
    width: 8rem;
    height: 100%;
    margin-top: 0;
  
    display: flex;
  }

  .menuo .butono-al-nula-pagho-entenilo img {
    height: 4rem;
    width: auto;
  }

  .menuo .ceteraj-butonoj-entenilo {
    width: 100%;
    height: 100%;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  
}