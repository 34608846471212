.parolobalono div {
  background-color: var(--blanka-koloro);

  border: none;
  padding: 1rem 5rem;
  font-weight: 300;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  border-radius: 1.5rem;

  width: 100%;
}