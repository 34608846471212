#nula-pagho {
  width: 100%;
  height: 100%;
  background-color: var(--verda-koloro);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#nula-pagho .subtitolo {
  color: var(--blanka-koloro);
  font-size: 3rem;
  margin-bottom: -4rem;
}

#nula-pagho h1 {
  color: var(--blanka-koloro);
  font-size: 16rem;
}

#nula-pagho .butono-al-chefo {
  margin-top: 5rem;
}

@media (max-width: 1200px) {
	#nula-pagho h1 {
		font-size: 12rem;
	}

  #nula-pagho .subtitolo {
    font-size: 2.5rem;
    margin-bottom: -1.5rem;
  }
}

@media (max-width: 750px) {
	#nula-pagho {
    padding-bottom: 10rem;
  }
  
  #nula-pagho h1 {
		font-size: 6rem;
	}

  #nula-pagho .subtitolo {
    font-size: 2rem;
    margin-bottom: -1rem;
  }
}

