:root {
	font-size: 60%;

	--fona-koloro: #fdfdfd;
  --teksto-koloro: #414141;

  --verda-koloro: #04aa72;
	--malluma-verda-koloro: #619b85;
	--verda-koloro-travidebla: rgb(4, 170, 114, 0.4);

  --blanka-koloro: #ffffff;
	--orangha-koloro: #fe7f2e;
	--nigra-koloro: #000000;
	--griza-koloro: #efefef;
	--luma-griza-koloro: #f8f8f8;
}

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

html, body, #root {
	height: 100vh;
}

body {
	background: var(--fona-koloro);
}

#root {
	display: flex;
	align-items: center;
	justify-content: center;
}

body,
input,
button,
textarea {
	font-size: 1.6rem;
	font-family: Poppins, sans-serif;
}

@media (min-width: 1200px) {
	:root {
		font-size: 75%;
	}
}

.kreskebla {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.kreskebla:hover,
.kreskebla:focus,
.kreskebla:active {
  transform: scale(1.15);
}

/* Firefox */
/*
* {
  scrollbar-width: 10rem;
  scrollbar-color: #eaeef4;
}
*/

/* Chrome, Edge kaj Safari */
*::-webkit-scrollbar {
  width: 2rem;
	left: -1rem;
}

*::-webkit-scrollbar-track {
  background: var(--blanka-koloro);
	border-radius: 2.5rem;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

*::-webkit-scrollbar-thumb {
  background-color: #eaeef4;
  border-radius: 2.5rem;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}